import { Spin } from "antd";
import CicoLogo from "../../../assets/images/branding/cico-white-logo.svg";

const CicoGlobalLoader = ({
  includeLogo = true,
  loader = <Spin />,
  isDarkBg = true,
}) => {
  return (
    <div className={`cico-loader ${isDarkBg ? "dark" : "light"}`}>
      {includeLogo ? <img src={CicoLogo} alt="Cico" /> : null}
      {loader}
    </div>
  );
};
export default CicoGlobalLoader;
