import { createStore, applyMiddleware } from "redux";
import Reducer from "../rootReducer";
import Thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const middleware = [Thunk];

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth", "user"],
};

export const createStoreWithMiddleware = applyMiddleware(...middleware)(
  createStore
);
const composeEnhancers = composeWithDevTools({
  trace: true,
  traceLimit: 25,
});

const PersistReducer = persistReducer(persistConfig, Reducer);
let Store = createStore(
  PersistReducer,
  {},
  process.env.REACT_APP_ENVIRONMENT === "PRODUCTION" ||
    process.env.REACT_APP_ENVIRONMENT === "STAGING"
    ? applyMiddleware(...middleware)
    : composeEnhancers(applyMiddleware(...middleware))
);

const persistStor = persistStore(Store);
export { persistStor };
export default Store;
