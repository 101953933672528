export const SET_SERVICE_CALL_FAILED = "SET_SERVICE_CALL_FAILED";
export const SET_FORM_SUBMISSION_IN_PROGRESS =
  "SET_FORM_SUBMISSION_IN_PROGRESS";
export const SET_FORM_SUBMISSION_SUCCESSFUL = "SET_FORM_SUBMISSION_SUCCESSFUL";
export const SET_FORM_ERRORS = "SET_FORM_ERRORS";

export const DASHBOARD_ACTIONS = {
  SET_LOADER: "SET_LOADER",
  SET_SERVICE_CALL_FAILED: "SET_SERVICE_CALL_FAILED",
  SET_CONFIG_ORGANISATIONS: "SET_CONFIG_ORGANISATIONS",
  SET_ORGANISATIONS: "SET_ORGANISATIONS",
  SET_FORM_SUBMISSION_IN_PROGRESS: "SET_FORM_SUBMISSION_IN_PROGRESS",
  SET_FORM_SUBMISSION_SUCCESSFUL: "SET_FORM_SUBMISSION_SUCCESSFUL",
};

export const setLoader = (isLoading) => ({
  type: DASHBOARD_ACTIONS.SET_LOADER,
  payload: {
    isLoading: isLoading,
  },
});

export const setConfigOrganisations = (data) => {
  return {
    type: DASHBOARD_ACTIONS.SET_CONFIG_ORGANISATIONS,
    payload: data,
  };
};

export const setOrganisations = (data) => {
  return {
    type: DASHBOARD_ACTIONS.SET_ORGANISATIONS,
    payload: data,
  };
};

export const setServiceCallFailed = (hasFailed, error) => ({
  type: SET_SERVICE_CALL_FAILED,
  payload: {
    isLoading: false,
    serviceCallFailed: hasFailed,
    serviceCallError: error,
  },
});

export const setFormSubmissionInProgress = (inProgress) => ({
  type: SET_FORM_SUBMISSION_IN_PROGRESS,
  payload: {
    formSubmissionInProgress: inProgress,
  },
});

export const setFormSubmissionSuccessful = (isSuccessful) => ({
  type: SET_FORM_SUBMISSION_SUCCESSFUL,
  payload: {
    formSubmissionSuccessful: isSuccessful,
  },
});

export const setFormErrors = (formErrors) => ({
  type: SET_FORM_ERRORS,
  payload: {
    formErrors: formErrors,
  },
});
