export const SET_SERVICE_CALL_FAILED = "SET_SERVICE_CALL_FAILED";
export const SET_UPDATE_WORKSCHEDULE = "SET_UPDATE_WORKSCHEDULE";
export const SET_WORKSCHEDULE_DETAILS = "SET_WORKSCHEDULE_DETAILS";
export const SET_FORM_SUBMISSION_IN_PROGRESS =
  "SET_FORM_SUBMISSION_IN_PROGRESS";
export const SET_FORM_SUBMISSION_SUCCESSFUL = "SET_FORM_SUBMISSION_SUCCESSFUL";
export const SET_FORM_ERRORS = "SET_FORM_ERRORS";

export const WORKSCHEDULE_ACTIONS = {
  SET_SELECTED_WORKSCHEDULE_ID:
    "WORKSCHEDULE_ACTIONS.SET_SELECTED_WORKSCHEDULE_ID",
  SET_LOADER: "SET_LOADER",
  SET_SERVICE_CALL_FAILED: "SET_SERVICE_CALL_FAILED",
  SET_WORKSCHEDULE_LIST: "SET_WORKSCHEDULE_LIST",
  SET_WORKSCHEDULE_DETAILS: "SET_WORKSCHEDULE_DETAILS",
  SET_FORM_SUBMISSION_IN_PROGRESS: "SET_FORM_SUBMISSION_IN_PROGRESS",
  SET_FORM_SUBMISSION_SUCCESSFUL: "SET_FORM_SUBMISSION_SUCCESSFUL",
  SET_WORKSCHEDULE_ORGANISATIONS: "SET_WORKSCHEDULE_ORGANISATIONS",
};
export const setLoader = (isLoading) => ({
  type: WORKSCHEDULE_ACTIONS.SET_LOADER,
  payload: {
    isLoading: isLoading,
  },
});

export const setWorkscheduleList = (data) => {
  return {
    type: WORKSCHEDULE_ACTIONS.SET_WORKSCHEDULE_LIST,
    payload: data,
  };
};

export const setWorkscheduleDetails = (WORKSCHEDULEDetails) => ({
  type: WORKSCHEDULE_ACTIONS.SET_WORKSCHEDULE_DETAILS,
  payload: WORKSCHEDULEDetails,
});

export const setWorkscheduleOrganisations = (data) => {
  return {
    type: WORKSCHEDULE_ACTIONS.SET_WORKSCHEDULE_ORGANISATIONS,
    payload: data,
  };
};
export const setFormSubmissionInProgress = (inProgress) => ({
  type: SET_FORM_SUBMISSION_IN_PROGRESS,
  payload: {
    formSubmissionInProgress: inProgress,
  },
});

export const setFormSubmissionSuccessful = (isSuccessful) => ({
  type: SET_FORM_SUBMISSION_SUCCESSFUL,
  payload: {
    formSubmissionSuccessful: isSuccessful,
  },
});
export const setServiceCallFailed = (hasFailed, error) => ({
  type: SET_SERVICE_CALL_FAILED,
  payload: {
    isLoading: false,
    serviceCallFailed: hasFailed,
    serviceCallError: error,
  },
});
export const setSelectedWorkscheduleId = (id) => ({
  type: WORKSCHEDULE_ACTIONS.SET_SELECTED_WORKSCHEDULE_ID,
  payload: id,
});

export const setFormErrors = (formErrors) => ({
  type: SET_FORM_ERRORS,
  payload: {
    formErrors: formErrors,
  },
});
