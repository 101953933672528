import { NOTIFICATION_ACTIONS } from "./action";

const initialState = {
  notificationItems: {},
};

const NotificationReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case NOTIFICATION_ACTIONS.SET_FORM_ERRORS:
      return {
        ...state,
        ...action.payload,
      };
    case NOTIFICATION_ACTIONS.SET_NOTIFICATION_ITEMS:
      return {
        ...state,
        notificationItems: payload,
      };
    case NOTIFICATION_ACTIONS.SET_FORM_SUBMISSION_IN_PROGRESS:
      return {
        ...state,
        ...action.payload,
      };
    case NOTIFICATION_ACTIONS.SET_FORM_SUBMISSION_SUCCESSFUL:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

export default NotificationReducer;
