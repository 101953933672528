import { CONFIG_ACTIONS } from "./action";

const initialState = {
  instructionText:
    "CICO Configuration is added to this section. Fill carefully the mandatory details and the save the configuration. Saved details can be updated.",
  configurationList: [],
  selectedConfigId: 1,
  selectedConfigItem: {},
  formSubmissionInProgress: false,
  formSubmissionSuccessful: false,
};

const ConfigReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case CONFIG_ACTIONS.SET_SELECTED_CONFIG_ID:
      return {
        ...state,
        selectedConfigId: payload,
      };
    case CONFIG_ACTIONS.SET_CONFIG_LIST:
      return {
        ...state,
        configurationList: payload,
      };
    case CONFIG_ACTIONS.SET_CONFIG_DETAILS:
      return {
        ...state,
        selectedConfigItem: payload,
      };
    case CONFIG_ACTIONS.SET_FORM_SUBMISSION_IN_PROGRESS:
      return {
        ...state,
        ...action.payload,
      };

    case CONFIG_ACTIONS.SET_FORM_SUBMISSION_SUCCESSFUL:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

export default ConfigReducer;
