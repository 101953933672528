import { WORKLOCATION_ACTIONS } from "./action";

const initialState = {
  instructionText:
    "CICO location is added to this section. Fill carefully the mandatory details and the save the location. Saved details can be updated.",
  worklocationList: [],
  selectedActivityId: 1,
  selectedActivityItem: {},
  formSubmissionInProgress: false,
  formSubmissionSuccessful: false,
};

const WorklocationReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case WORKLOCATION_ACTIONS.SET_SELECTED_ACTIVITY_ID:
      return {
        ...state,
        selectedActivityId: payload,
      };
    // case WORKLOCATION_ACTIONS.UPDATE_ITEM_STATUS:
    //   const updatedList = state.worklocationList.map((o) =>
    //     o.id === payload.id ? { ...o, is_active: payload.status } : o
    //   );
    //   return {
    //     ...state,
    //     worklocationList: updatedList,
    //   };
    case WORKLOCATION_ACTIONS.SET_LOCATION_LIST:
      return {
        ...state,
        worklocationList: payload,
      };
    case WORKLOCATION_ACTIONS.SET_LOCATION_DETAILS:
      return {
        ...state,
        selectedActivityItem: payload,
      };
    case WORKLOCATION_ACTIONS.SET_FORM_SUBMISSION_IN_PROGRESS:
      return {
        ...state,
        ...action.payload,
      };

    case WORKLOCATION_ACTIONS.SET_FORM_SUBMISSION_SUCCESSFUL:
      return {
        ...state,
        ...action.payload,
      };
    case WORKLOCATION_ACTIONS.SET_FORM_ERRORS:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

export default WorklocationReducer;
