export const SET_SERVICE_CALL_FAILED = "SET_SERVICE_CALL_FAILED";
export const SET_UPDATE_OVERTIME = "SET_UPDATE_OVERTIME";
export const SET_OVERTIME_DETAILS = "SET_OVERTIME_DETAILS";
export const SET_FORM_SUBMISSION_IN_PROGRESS =
  "SET_FORM_SUBMISSION_IN_PROGRESS";
export const SET_FORM_SUBMISSION_SUCCESSFUL = "SET_FORM_SUBMISSION_SUCCESSFUL";
export const SET_FORM_ERRORS = "SET_FORM_ERRORS";

export const OVERTIME_ACTIONS = {
  SET_SELECTED_OVERTIME_ID: "OVERTIME_ACTIONS.SET_SELECTED_OVERTIME_ID",
  SET_LOADER: "SET_LOADER",
  SET_SERVICE_CALL_FAILED: "SET_SERVICE_CALL_FAILED",
  SET_OVERTIME_LIST: "SET_OVERTIME_LIST",
  SET_OVERTIME_DETAILS: "SET_OVERTIME_DETAILS",
  SET_FORM_SUBMISSION_IN_PROGRESS: "SET_FORM_SUBMISSION_IN_PROGRESS",
  SET_FORM_SUBMISSION_SUCCESSFUL: "SET_FORM_SUBMISSION_SUCCESSFUL",
  SET_OVERTIME_ORGANISATIONS: "SET_OVERTIME_ORGANISATIONS",
};
export const setLoader = (isLoading) => ({
  type: OVERTIME_ACTIONS.SET_LOADER,
  payload: {
    isLoading: isLoading,
  },
});

export const setOvertimeList = (data) => {
  return {
    type: OVERTIME_ACTIONS.SET_OVERTIME_LIST,
    payload: data,
  };
};

export const setOvertimeDetails = (overtimeDetails) => ({
  type: OVERTIME_ACTIONS.SET_OVERTIME_DETAILS,
  payload: overtimeDetails,
});

export const setOvertimeOrganisations = (data) => {
  return {
    type: OVERTIME_ACTIONS.SET_OVERTIME_ORGANISATIONS,
    payload: data,
  };
};
export const setFormSubmissionInProgress = (inProgress) => ({
  type: SET_FORM_SUBMISSION_IN_PROGRESS,
  payload: {
    formSubmissionInProgress: inProgress,
  },
});

export const setFormSubmissionSuccessful = (isSuccessful) => ({
  type: SET_FORM_SUBMISSION_SUCCESSFUL,
  payload: {
    formSubmissionSuccessful: isSuccessful,
  },
});
export const setServiceCallFailed = (hasFailed, error) => ({
  type: SET_SERVICE_CALL_FAILED,
  payload: {
    isLoading: false,
    serviceCallFailed: hasFailed,
    serviceCallError: error,
  },
});
export const setSelectedOvertimeId = (id) => ({
  type: OVERTIME_ACTIONS.SET_SELECTED_OVERTIME_ID,
  payload: id,
});

export const setFormErrors = (formErrors) => ({
  type: SET_FORM_ERRORS,
  payload: {
    formErrors: formErrors,
  },
});
