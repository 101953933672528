import axios from "axios";
import * as axiosRetry from "retry-axios";

import { logout } from "../pages/login/store/action";

import { connect } from "react-redux";

import Store from "../shared/redux/store/store";

const url = process.env[`REACT_APP_API_BASE_URL`];
const Instance = axios.create({
  baseURL: `${url}api/v1`,
});
export const tenantAxios = axios.create({
  baseURL: url,
});
Instance.interceptors.request.use(
  (config) => {
    const Auth = Store.getState()?.auth;
    if (
      config.headers.common.Authorization === undefined &&
      Auth.token.accessToken &&
      Auth.token.refreshToken
    ) {
      config.headers.common[
        "Authorization"
      ] = `Bearer ${Auth.token.accessToken}`;
      config.headers.common["REFRESH-TOKEN"] = `${Auth.token.refreshToken}`;
      config.headers.common["X-DTS-SCHEMA"] = `${Auth.tenant}`;
    }

    return {
      ...config,
      signal: config.signal && config.signal,
    };
  },
  (error) => {
    return Promise.reject(error);
  }
);

Instance.interceptors.response.use(
  (response) => {
    let data = {};
    if (response.status !== 204) {
      data = response.data;
    }
    return { ...response, data: data };
  },
  (error) => {
    let message = error.message || "";
    if (error.response !== undefined) {
      switch (error.response.status) {
        case 401:
          try {
            const store = require("../shared/redux/store/store").default;
            store.dispatch(logout());
          } catch (e) {
            console.error(e);
          }
          // remove500Error();
          break;

        case 403:
          if (error.response.data.detail === "PROXY_INVALID") {
            // const store = require("../shared/redux/store/store").default;
            // store.dispatch(resetAllData());
          }
          message = "You are unauthorised to perform this action";
          // remove500Error();
          break;

        case 404:
          message = "Requested resource is unavailable";
          // remove500Error();
          break;

        case 500:
          const method = error.config.method;
          if (method === "get") {
            // const store = require("../shared/redux/store/store").default;
          }
          break;
        case 502:
          if (axiosRetry.getConfig(error)?.currentRetryAttempt === 2) {
            // const store = require("../shared/redux/store/store").default;
          }
          break;
        default:
          break;
      }
    } else if (axios.isCancel(error)) {
      message = error.message || "";
    } else if (error.isAxiosError && error.message === "Network Error") {
      // const store = require("../shared/redux/store/store").default;
    } else {
      message = "Resource server is unreachable";
    }
    return Promise.reject({ ...error, message: message });
  }
);

// function remove500Error() {
//   const store = require("../shared/redux/store/store").default;
// }

export function axiosGet(url) {
  return Instance.get(url);
}

export function axiosPost(url, body) {
  return Instance.post(url, body);
}

export const setGlobalToken = (token) => {
  if (token) {
    Instance.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${token.accessToken}`;
    Instance.defaults.headers.common["REFRESH-TOKEN"] = `${token.refreshToken}`;
  } else {
    delete Instance.defaults.headers.common["Authorization"];
    delete Instance.defaults.headers["REFRESH-TOKEN"];
  }
};

export const setGlobalTenant = (tenant) => {
  if (tenant) {
    Instance.defaults.headers.common["X-DTS-SCHEMA"] = `${tenant}`;
  } else {
    delete Instance.defaults.headers.common["X-DTS-SCHEMA"];
  }
};

export const resetAxios = () => {
  delete Instance.defaults.headers.common["Authorization"];
  axiosRetry.detach(interceptorId);
};

Instance.defaults.raxConfig = {
  statusCodesToRetry: [[502, 502]],
  instance: Instance,
  retry: 2,
  httpMethodsToRetry: ["GET", "DELETE", "PUT"],
};

const mapStateToProps = (state) => {
  const { token, tenant } = state.auth;
  return {
    token,
    tenant,
  };
};

const interceptorId = axiosRetry.attach(Instance);

const connector = connect(mapStateToProps, null);

export default connector(Instance);
