import { APP_REDUCER_ACTIONS } from "./action";

const initialState = {
  confirmationInfo: {
    visibility: false,
    headerText: undefined,
    bodyText: undefined,
    forWhat: "",
    okText: undefined,
    cancelText: undefined,
    extraInfo: null,
    cancelBtnFn: undefined,
    okBtnFn: undefined,
  },
};

const AppReducer = (
    state = initialState,
    { type, payload = {} },
  ) => {
    switch (type) {
      case APP_REDUCER_ACTIONS.SET_CONFIRMATION_INFO:
        return {
          ...state,
          confirmationInfo: {
            ...initialState.confirmationInfo,
            ...payload,
          },
        };
  
      case APP_REDUCER_ACTIONS.RESET_CONFIRMATION_INFO:
        return {
          ...state,
          confirmationInfo: initialState.confirmationInfo,
        };
  
      default:
        return state;
    }
  };

export default AppReducer;
