import { combineReducers } from "redux";
import { resetAxios } from "../../utils/axios";
import AppReducer from "../../pages/app/store/reducer";
import AuthReducer, * as auth from "../../pages/login/store/reducer";
import UserReducer from "../../pages/userProfile/store/reducer";
import ReportReducer from "../../pages/reports/store/reducer";
import WorkLocationReducer from "../../pages/settings/components/worklocations/store/reducer";
import ConfigReducer from "../../pages/settings/components/configuration/store/reducer";
import QRcodeReducer from "../../pages/qrCode/store/reducer";
import NotificationReducer from "../../pages/settings/components/notification/components/notificationSettings/store/reducer";
import EmailSettingReducer from "../../pages/settings/components/notification/components/emailSettings/store/reducer";
import OffsiteNotificationReducer from "../../pages/settings/components/notification/components/emailSettings/components/store/reducer";
import DashboardReducer from "../../pages/dashboard/store/reducer";
import OvertimeReducer from "../../pages/settings/components/Overtime/store/reducer";
import WorkscheduleReducer from "../../pages/settings/components/workschedule/store/reducer";

const Reducer = combineReducers({
  app: AppReducer,
  auth: AuthReducer,
  user: UserReducer,
  report: ReportReducer,
  config: ConfigReducer,
  worklocation: WorkLocationReducer,
  qrcode: QRcodeReducer,
  notification: NotificationReducer,
  emailsettings: EmailSettingReducer,
  offsitenotifications: OffsiteNotificationReducer,
  dashboard: DashboardReducer,
  overtime: OvertimeReducer,
  workschedule: WorkscheduleReducer,
});

export const getAuthState = (state) => state.auth;

export const getPermissions = (state) =>
  auth.getPermissions(getAuthState(state));

const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === "AUTH_ACTIONS/USER_LOGGED_OUT") {
    state = undefined;
    resetAxios();
  }
  return Reducer(state, action);
};

export default rootReducer;
