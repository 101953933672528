import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import "./styles/index.scss";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
// import { I18nProvider } from "@lingui/react";
import Store, { persistStor } from "./shared/redux/store/store";
import CicoGlobalLoader from "./shared/components/cicoGlobalLoader/cicoGlobalLoader";
import { PersistGate } from "redux-persist/integration/react";
const App = lazy(() => import("./pages/app/app"));

window.cicoData = Store;
ReactDOM.render(
  <>
    <Provider store={Store}>
      <PersistGate loading={null} persistor={persistStor}>
        <Router>
          <Suspense fallback={<CicoGlobalLoader />}>
            <App />
          </Suspense>
        </Router>
      </PersistGate>
    </Provider>
  </>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
