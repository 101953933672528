export const REPORT_ACTIONS = {
  SET_USERS: "SET_USERS",
  SET_GENERATED_FILES: "SET_GENERATED_FILES",
  SET_LOADER: "SET_LOADER",
  SET_SERVICE_CALL_FAILED: "SET_SERVICE_CALL_FAILED",
  SET_FORM_SUBMISSION_IN_PROGRESS: "SET_FORM_SUBMISSION_IN_PROGRESS",
  SET_FORM_SUBMISSION_SUCCESSFUL: "SET_FORM_SUBMISSION_SUCCESSFUL",
  SET_REPORT_DOWNLOAD_LIST: "SET_REPORT_DOWNLOAD_LIST",
  SET_ERROR: "SET_ERROR",
};

export const setLoader = (isLoading) => ({
  type: REPORT_ACTIONS.SET_LOADER,
  payload: {
    isLoading: isLoading,
  },
});

export const setUsers = (data) => {
  return {
    type: REPORT_ACTIONS.SET_USERS,
    payload: data,
  };
};

export const setGeneratedFiles = (data) => {
  return {
    type: REPORT_ACTIONS.SET_GENERATED_FILES,
    payload: data,
  };
};

export const setReportDownloadList = (data) => {
  return {
    type: REPORT_ACTIONS.SET_REPORT_DOWNLOAD_LIST,
    payload: data,
  };
};

export const setError = (error) => ({
  type: REPORT_ACTIONS.SET_ERROR,
  payload: error,
});

export const setServiceCallFailed = (hasFailed, error) => ({
  type: REPORT_ACTIONS.SET_SERVICE_CALL_FAILED,
  payload: {
    isLoading: false,
    serviceCallFailed: hasFailed,
    serviceCallError: error,
  },
});

export const setFormSubmissionInProgress = (inProgress) => ({
  type: REPORT_ACTIONS.SET_FORM_SUBMISSION_IN_PROGRESS,
  payload: {
    formSubmissionInProgress: inProgress,
  },
});

export const setFormSubmissionSuccessful = (isSuccessful) => ({
  type: REPORT_ACTIONS.SET_FORM_SUBMISSION_SUCCESSFUL,
  payload: {
    formSubmissionSuccessful: isSuccessful,
  },
});

export const setFormErrors = (formErrors) => ({
  type: REPORT_ACTIONS.SET_FORM_ERRORS,
  payload: {
    formErrors: formErrors,
  },
});
