export const WORKLOCATION_ACTIONS = {
  SET_SELECTED_ACTIVITY_ID: "SET_SELECTED_ACTIVITY_ID",
  UPDATE_ITEM_STATUS: ".UPDATE_ITEM_STATUS",
  SET_LOADER: "SET_LOADER",
  SET_SERVICE_CALL_FAILED: "SET_SERVICE_CALL_FAILED",
  SET_LOCATION_LIST: "SET_LOCATION_LIST",
  SET_LOCATION_DETAILS: "SET_LOCATION_DETAILS",
  SET_FORM_SUBMISSION_IN_PROGRESS: "SET_FORM_SUBMISSION_IN_PROGRESS",
  SET_FORM_SUBMISSION_SUCCESSFUL: "SET_FORM_SUBMISSION_SUCCESSFUL",
};

export const setSelectedActivity = (id) => ({
  type: WORKLOCATION_ACTIONS.SET_SELECTED_ACTIVITY_ID,
  payload: id,
});

export const toggleStatus = (id, status) => ({
  type: WORKLOCATION_ACTIONS.UPDATE_ITEM_STATUS,
  payload: { id, status },
});

export const setLoader = (isLoading) => ({
  type: WORKLOCATION_ACTIONS.SET_LOADER,
  payload: {
    isLoading: isLoading,
  },
});

export const setLocationList = (data) => {
  return {
    type: WORKLOCATION_ACTIONS.SET_LOCATION_LIST,
    payload: data,
  };
};

export const setUpdateLocation = (locations) => ({
  type: WORKLOCATION_ACTIONS.SET_UPDATE_LOCATION,
  payload: {
    locations: locations,
    isLoading: false,
    serviceCallFailed: false,
    formSubmissionInProgress: false,
    formSubmissionSuccessful: false,
  },
});

export const setServiceCallFailed = (hasFailed, error) => ({
  type: WORKLOCATION_ACTIONS.SET_SERVICE_CALL_FAILED,
  payload: {
    isLoading: false,
    serviceCallFailed: hasFailed,
    serviceCallError: error,
  },
});

export const setLocationDetails = (locationDetails) => ({
  type: WORKLOCATION_ACTIONS.SET_LOCATION_DETAILS,
  payload: locationDetails,
});

export const setFormSubmissionInProgress = (inProgress) => ({
  type: WORKLOCATION_ACTIONS.SET_FORM_SUBMISSION_IN_PROGRESS,
  payload: {
    formSubmissionInProgress: inProgress,
  },
});

export const setFormSubmissionSuccessful = (isSuccessful) => ({
  type: WORKLOCATION_ACTIONS.SET_FORM_SUBMISSION_SUCCESSFUL,
  payload: {
    formSubmissionSuccessful: isSuccessful,
  },
});

export const setFormErrors = (formErrors) => ({
  type: WORKLOCATION_ACTIONS.SET_FORM_ERRORS,
  payload: {
    formErrors: formErrors,
  },
});
