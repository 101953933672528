import { clearSessionStorage } from "../../../utils/global";

export const AUTH_ACTIONS = {
  SAVE_TENANT: "AUTH_ACTIONS/SAVE_TENANT",
  SAVE_TOKEN: "AUTH_ACTIONS/SAVE_TOKEN",
  PERMISSIONS: "AUTH_ACTIONS/PERMISSIONS",
  USER_LOGGED_OUT: "AUTH_ACTIONS/USER_LOGGED_OUT",
};

export const saveTenant = (tenant) => ({
  type: AUTH_ACTIONS.SAVE_TENANT,
  payload: tenant,
});

export const saveToken = (token) => {
  return {
    type: AUTH_ACTIONS.SAVE_TOKEN,
    payload: token,
  };
};

export const setPermissions = (data) => {
  return {
    type: AUTH_ACTIONS.PERMISSIONS,
    payload: data,
  };
};

export const logout = () => {
  clearSessionStorage();
  saveToken({});
  return { type: AUTH_ACTIONS.USER_LOGGED_OUT, payload: null };
};
