import { REPORT_ACTIONS } from "./action";

const initialState = {
  reports: [],
  users: [],
  reportDownloadList: [],
};

const ReportReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case REPORT_ACTIONS.SET_USERS:
      return {
        ...state,
        users: payload,
      };
    case REPORT_ACTIONS.SET_GENERATED_FILES:
      return {
        ...state,
        reports: payload,
      };

    case REPORT_ACTIONS.SET_REPORT_DOWNLOAD_LIST:
      return {
        ...state,
        reportDownloadList: payload,
      };
    case REPORT_ACTIONS.SET_ERROR:
      return {
        ...state,
        error: payload,
      };

    default:
      return state;
  }
};

export default ReportReducer;
