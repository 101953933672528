import { USER_PROFILE_ACTION } from "./action";

const initialState = {
  loader: false,
  userData: null,
  locations: [
    { id: 1, title: "Singapore" },
    { id: 2, title: "Mumbai" },
    { id: 3, title: "USA" },
    { id: 4, title: "German" },
  ],
};

const UserReducer = (state = initialState, { type, payload = {} }) => {
  switch (type) {
    case USER_PROFILE_ACTION.SET_LOADING:
      return {
        ...state,
        loader: payload,
      };
    case USER_PROFILE_ACTION.SET_USER_DATA:
      return {
        ...state,
        userData: payload,
      };
    case USER_PROFILE_ACTION.SET_LOCATIONS:
      return {
        ...state,
        locations: payload,
      };
    default:
      return state;
  }
};

export default UserReducer;
