import { QR_ACTIONS } from "./action";

const initialState = {
  instructionText:
    "Locations for QR scanning are listed here.'View QR' link will show related QR code for location.QR logs can be seen here with the location, author and created on details",

  qrlocationList: [],
  selectedQRItem: {},
  qrlogs: [],
};

const QRcodeReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case QR_ACTIONS.SET_QRLOCATION_LIST:
      return {
        ...state,
        qrlocationList: payload,
      };
    case QR_ACTIONS.SET_QRLOCATION_DETAILS:
      return {
        ...state,
        selectedQRItem: payload,
      };
    case QR_ACTIONS.SET_QRLOGS_DETAILS:
      return {
        ...state,
        qrlogs: payload,
      };

    default:
      return state;
  }
};

export default QRcodeReducer;
