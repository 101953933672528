export const SET_SERVICE_CALL_FAILED = "SET_SERVICE_CALL_FAILED";
export const SET_FORM_SUBMISSION_IN_PROGRESS =
  "SET_FORM_SUBMISSION_IN_PROGRESS";
export const SET_FORM_SUBMISSION_SUCCESSFUL = "SET_FORM_SUBMISSION_SUCCESSFUL";
export const SET_FORM_ERRORS = "SET_FORM_ERRORS";
export const SET_NOTIFICATION_ITEMS = "SET_NOTIFICATION_ITEMS";

export const NOTIFICATION_ACTIONS = {
  SET_LOADER: "SET_LOADER",
  SET_SERVICE_CALL_FAILED: "SET_SERVICE_CALL_FAILED",
  SET_FORM_ERRORS: "SET_FORM_ERRORS",
  SET_FORM_SUBMISSION_IN_PROGRESS: "SET_FORM_SUBMISSION_IN_PROGRESS",
  SET_FORM_SUBMISSION_SUCCESSFUL: "SET_FORM_SUBMISSION_SUCCESSFUL",
  SET_NOTIFICATION_ITEMS: "SET_NOTIFICATION_ITEMS",
};

export const setLoader = (isLoading) => ({
  type: NOTIFICATION_ACTIONS.SET_LOADER,
  payload: {
    isLoading: isLoading,
  },
});

export const setNotificationItems = (data) => ({
  type: NOTIFICATION_ACTIONS.SET_NOTIFICATION_ITEMS,
  payload: data,
});

export const setServiceCallFailed = (hasFailed, error) => ({
  type: NOTIFICATION_ACTIONS.SET_SERVICE_CALL_FAILED,
  payload: {
    isLoading: false,
    serviceCallFailed: hasFailed,
    serviceCallError: error,
  },
});

export const setFormSubmissionInProgress = (inProgress) => ({
  type: SET_FORM_SUBMISSION_IN_PROGRESS,
  payload: {
    formSubmissionInProgress: inProgress,
  },
});

export const setFormSubmissionSuccessful = (isSuccessful) => ({
  type: SET_FORM_SUBMISSION_SUCCESSFUL,
  payload: {
    formSubmissionSuccessful: isSuccessful,
  },
});

export const setFormErrors = (formErrors) => ({
  type: NOTIFICATION_ACTIONS.SET_FORM_ERRORS,
  payload: {
    formErrors: formErrors,
  },
});
