import { EMAIL_ACTIONS } from "./action";

const initialState = {
  emailSettings: [],
  offsiteNotifications: [],
};

const EmailSettingReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case EMAIL_ACTIONS.SET_FORM_ERRORS:
      return {
        ...state,
        ...action.payload,
      };
    case EMAIL_ACTIONS.SET_EMAIL_SETTINGS:
      return {
        ...state,
        emailSettings: payload,
      };
    case EMAIL_ACTIONS.SET_FORM_SUBMISSION_IN_PROGRESS:
      return {
        ...state,
        ...action.payload,
      };
    case EMAIL_ACTIONS.SET_FORM_SUBMISSION_SUCCESSFUL:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

export default EmailSettingReducer;
