import { OVERTIME_ACTIONS } from "./action";
const initialState = {
  overtimeList: [],
  selectedOvertimeId: 1,
  overtimeOrganisations: null,
  selectedOvertimeItem: {},
  formSubmissionInProgress: false,
  formSubmissionSuccessful: false,
};

const OvertimeReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case OVERTIME_ACTIONS.SET_LOADER:
      return {
        ...state,
        isLoader: payload,
      };
    case OVERTIME_ACTIONS.SET_OVERTIME_LIST:
      return {
        ...state,
        overtimeList: payload,
      };
    case OVERTIME_ACTIONS.SET_OVERTIME_DETAILS:
      return {
        ...state,
        selectedOvertimeItem: payload,
      };
      case OVERTIME_ACTIONS.SET_OVERTIME_ORGANISATIONS:
      return {
        ...state,
        overtimeOrganisations: payload,
      };
    case OVERTIME_ACTIONS.SET_SELECTED_OVERTIME_ID:
      return {
        ...state,
        selectedOvertimeId: payload,
      };
    case OVERTIME_ACTIONS.SET_FORM_SUBMISSION_IN_PROGRESS:
      return {
        ...state,
        ...action.payload,
      };

    case OVERTIME_ACTIONS.SET_FORM_SUBMISSION_SUCCESSFUL:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};
export const getLoadingState = (state) => state.isLoader;

export default OvertimeReducer;
