export const USER_PROFILE_ACTION = {
  SET_LOADING: "USER_PROFILE_ACTION/SET_LOADING",
  SET_USER_DATA: "USER_PROFILE_ACTION/SET_USER_DATA",
  SET_LOCATIONS: "USER_PROFILE_ACTION/SET_LOCATIONS",
  SET_SERVICE_CALL_FAILED: "USER_PROFILE_ACTION/SET_SERVICE_CALL_FAILED",
};

export const setLoader = (isLoading) => ({
  type: USER_PROFILE_ACTION.SET_LOADING,
  payload: isLoading,
});

export const saveUserData = (user) => ({
  type: USER_PROFILE_ACTION.SET_USER_DATA,
  payload: user,
});

export const setLocations = (payload) => ({
  type: USER_PROFILE_ACTION.SET_LOCATIONS,
  payload: payload,
});

export const setServiceCallFailed = (hasFailed, error) => ({
  type: USER_PROFILE_ACTION.SET_SERVICE_CALL_FAILED,
  payload: {
    isLoading: false,
    serviceCallFailed: hasFailed,
    serviceCallError: error,
  },
});
