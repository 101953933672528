export const APP_REDUCER_ACTIONS = {
  SET_CONFIRMATION_INFO: "APP/SET_CONFIRMATION_INFO",
  RESET_CONFIRMATION_INFO: "APP/RESET_CONFIRMATION_INFO",
};

export const setConfirmationInfo = (confirmationData) => ({
  type: APP_REDUCER_ACTIONS.SET_CONFIRMATION_INFO,
  payload: confirmationData,
});

export const resetConfirmationInfo = () => ({
  type: APP_REDUCER_ACTIONS.RESET_CONFIRMATION_INFO,
});

