import { WORKSCHEDULE_ACTIONS } from "./action";
const initialState = {
  workscheduleList: [],
  selectedOvertimeId: 1,
  workscheduleListOrganisations: null,
  selectedOvertimeItem: {},
  formSubmissionInProgress: false,
  formSubmissionSuccessful: false,
};

const WorkscheduleReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case WORKSCHEDULE_ACTIONS.SET_LOADER:
      return {
        ...state,
        isLoader: payload,
      };
    case WORKSCHEDULE_ACTIONS.SET_WORKSCHEDULE_LIST:
      return {
        ...state,
        workscheduleList: payload,
      };
    case WORKSCHEDULE_ACTIONS.SET_WORKSCHEDULE_DETAILS:
      return {
        ...state,
        selectedOvertimeItem: payload,
      };
      case WORKSCHEDULE_ACTIONS.SET_WORKSCHEDULE_ORGANISATIONS:
      return {
        ...state,
        workscheduleListOrganisations: payload,
      };
    case WORKSCHEDULE_ACTIONS.SET_SELECTED_WORKSCHEDULE_ID:
      return {
        ...state,
        selectedOvertimeId: payload,
      };
    case WORKSCHEDULE_ACTIONS.SET_FORM_SUBMISSION_IN_PROGRESS:
      return {
        ...state,
        ...action.payload,
      };

    case WORKSCHEDULE_ACTIONS.SET_FORM_SUBMISSION_SUCCESSFUL:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};
export const getLoadingState = (state) => state.isLoader;

export default WorkscheduleReducer;
