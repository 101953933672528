import { AUTH_ACTIONS } from "./action";
import { setGlobalTenant, setGlobalToken } from "../../../utils/axios";

const initialState = {
  tenant: "",
  token: {
    accessToken: "",
    refreshToken: "",
  },
  permissions: [],
};

const AuthReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case AUTH_ACTIONS.SAVE_TENANT: {
      setGlobalTenant(payload);
      sessionStorage.setItem("tenant", payload.tenant);
      return { ...state, tenant: payload };
    }
    case AUTH_ACTIONS.SAVE_TOKEN: {
      setGlobalToken(payload);
      sessionStorage.setItem("accessToken", payload.accessToken);
      sessionStorage.setItem("refreshToken", payload.refreshToken);
      return { ...state, token: payload };
    }
    case AUTH_ACTIONS.PERMISSIONS: {
      return {
        ...state,
        permissions: payload,
      };
    }

    default:
      return state;
  }
};

export const getPermissions = (state) => {
  const permissionsMap = {
    CICO_ACTION_CONFIGURATIONS: true,
    CICO_ACTION_WORK_LOCATIONS: true,
    CICO_ACTION_OVERTIME_RULE:false,
    CICO_VIEW_CONFIGURATIONS: true,
    CICO_VIEW_NOTIFICATIONS: true,
    CICO_VIEW_QR_CODE: true,
    CICO_VIEW_REPORTEES_WORK_SCHEDULE: true,
    CICO_VIEW_REPORTS: true,
    CICO_VIEW_WORK_LOCATIONS: true,
    CICO_VIEW_WORK_SCHEDULE: true,
    CICO_VIEW_OVERTIME_RULE:true,

  };
  const permissionCodes = Object.keys(permissionsMap);

  if (state.permissions) {
    permissionCodes.forEach((permissionCode) => {
      permissionsMap[permissionCode] = permissionCode in state.permissions;
    });
  }
  return permissionsMap;
};

export default AuthReducer;
