// import qs from "querystring";
import Store from "../shared/redux/store/store";
import moment from "moment";

if (
  process.env.REACT_APP_ENVIRONMENT === "PRODUCTION" ||
  process.env.REACT_APP_ENVIRONMENT === "STAGING"
) {
  // overriding console log function.
  window.console.log = function () {};
}

const isFileTypeString = (file) => Boolean(typeof file === "string");

const isPdfFile = (file) => {
  return isFileTypeString(file)
    ? Boolean(file.search("pdf") !== -1)
    : Boolean(file.type === "application/pdf" || file.type === "pdf");
};

const getDistance = (coord, callback) => {
  try {
    const { google } = window;
    if (coord.source && coord.destination) {
      var origin1 = new google.maps.LatLng(coord.source.lat, coord.source.lng);
      var origin2 = new google.maps.LatLng(
        coord.destination.lat,
        coord.destination.lng
      );

      const _callback = (response, status) => {
        // See Parsing the Results for
        // the basics of a callback function.
        if (status === "ZERO_RESULTS") {
          callback && callback("No Result Found", null, response);
        } else if (status === "OK") {
          callback &&
            callback(
              status,
              response.rows[0].elements[0].distance.value / 1000,
              response
            );
        } else {
          console.error(status, "Not handled error.");
          callback && callback("Something Went Wrong", null, response);
        }
      };

      var service = new google.maps.DistanceMatrixService();
      service.getDistanceMatrix(
        {
          origins: [origin1],
          destinations: [origin2],
          travelMode: "DRIVING",
        },
        _callback
      );
    } else {
      console.error("Provide correct parameters!");
    }
  } catch (error) {
    console.error("DEV ERROR [getDistance FN]", error);
  }
};

// const generateQueryParamsString = (parameters) => {
//   Object.entries(parameters).forEach(([key, value]) => {
//     if (typeof value !== "string" && typeof value !== "number") {
//       parameters[key] = value.join(",");
//     }
//   });
//   return qs.stringify(parameters);
// };

const getQueryParametersAsObject = (forFilters = true) => {
  let url = window.location.href;
  let params = {};
  let queryString = url.split("?")[1];
  const filterDataTypes = {
    category: "Array",
    entity_id: "Array",
    expense_type: "Array",
    status: "Array",
    pending_approval_at: "Array",
    employee_id: "Array",
    from_date: "String",
    to_date: "String",
    item_no: "String",
    last_action_prior_to: "String",
    settled_on_from: "String",
    settled_on_to: "String",
    has_receipt: "Number",
    max_amount: "Number",
    min_amount: "Number",
  };

  if (queryString === undefined) {
    return {};
  }

  queryString.split("&").forEach((item) => {
    let tokens = item.split("=");
    let value = decodeURIComponent(tokens[1]);
    let regex = new RegExp(/^\d{2}[.-/]\d{2}[.-/]\d{4}$/);
    if (regex.test(value)) {
      params[tokens[0]] = value;
    } else {
      if (value.split(",").length > 1) {
        params[tokens[0]] = value
          .split(",")
          .map((v) => (isNaN(parseInt(v)) ? v : parseInt(v)));
      } else {
        if (["has_receipt", "max_amount", "min_amount"].includes(tokens[0])) {
          params[tokens[0]] = isNaN(parseInt(value)) ? value : parseInt(value);
        } else {
          params[tokens[0]] = value;
        }
      }
    }

    if (
      forFilters &&
      filterDataTypes.hasOwnProperty(tokens[0]) &&
      filterDataTypes[tokens[0]] === "Array" &&
      !Array.isArray(params[tokens[0]]) &&
      params[tokens[0]]
    ) {
      params[tokens[0]] = [params[tokens[0]]];
    }
  });
  return params;
};

const isPendingStatusInQuery = () => {
  let urlQueryParameters = getQueryParametersAsObject();
  if (urlQueryParameters.hasOwnProperty("status")) {
    if (
      typeof urlQueryParameters?.status === "string" &&
      urlQueryParameters?.status !== ""
    ) {
      return urlQueryParameters?.status === "PENDNG";
    } else if (Array.isArray(urlQueryParameters?.status)) {
      if (urlQueryParameters?.status.length === 1)
        return urlQueryParameters?.status.includes("PENDNG");
      else return false;
    }
  }
  return true;
};

const preciseDecimal = (num, precision) => {
  try {
    const splitByDecimal = num.toString().split(".");
    const decimal = splitByDecimal[1] || "0";
    const convertedDecimal =
      decimal.length === precision
        ? decimal /* if same then dont do any thing return the same value */
        : decimal.length > precision
        ? decimal.slice(
            0,
            precision
          ) /* if greater than precision then trim extra */
        : `${decimal}${
            Number(0)
              .toFixed(precision - decimal.length)
              .split(".")[1]
          }`; /* if lesser than precision then add 0's */
    const returnVal = [splitByDecimal[0], convertedDecimal].join(".");
    return returnVal;
  } catch (error) {
    console.error("DEV ERROR :: ", error);
    return num.toString();
  }
};

const stringTemplating = (context, stringTemplate) => {
  try {
    let returnSting = stringTemplate || "";
    for (const [key, value] of Object.entries(context)) {
      returnSting = returnSting.replace(
        new RegExp("{" + key + "}", "gi"),
        value
      );
    }

    return returnSting || stringTemplate;
  } catch (error) {
    console.error("DEV ERROR : ", error);
    return stringTemplate;
  }
};

const setDocumentTitle = (title) => {
  document.title = typeof title === "string" ? `Cico - ${title}` : "Cico";
};

const removeLastSlash = (url) => {
  try {
    let newUrl = url || "";
    const index = newUrl.length - 1;
    if (newUrl[index] === "/") {
      newUrl.slice(0, index);
    }
    return newUrl;
  } catch (error) {
    console.error("DEV ERROR: Failed to remove last slash from url", error);
    return url;
  }
};

const scrollToElem = (selector) => {
  try {
    const elem = document.querySelector(selector);
    // eslint-disable-next-line no-unused-expressions
    elem?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  } catch (error) {
    console.error("DEV ERROR: Failed to scroll to element", error);
  }
};

const removeParamsFromObject = (
  paramsObject,
  keysToRemove,
  conditionForRemoval = true
) => {
  try {
    let returnObject = { ...paramsObject };
    if (conditionForRemoval) {
      for (const [key] of Object.entries(paramsObject)) {
        if (keysToRemove.includes(key)) {
          delete returnObject[key];
        }
      }
    }
    return returnObject;
  } catch (error) {
    console.error("DEV ERROR :: ", error);
    return paramsObject;
  }
};

const timeZoneMomentDate = (date) => {
  try {
    let timeZone = "";

    if (Store.getState()?.auth?.user?.timezone?.title) {
      timeZone = Store.getState()?.auth?.user?.timezone?.title;
    } else {
      console.error("No Time Zone Found");
      return moment();
    }

    return moment.utc(date, ["DD/MM/YYYYTHH:mm:ss[.mmm]TZD"]).tz(timeZone);
  } catch (error) {
    return moment();
  }
};

const clearSessionStorage = () => {
  sessionStorage.clear();
  localStorage.clear();
};

const formatValue = (str) => {
  if(str){
    let lowerStr = str.toLowerCase();
    let value = lowerStr.includes("_")
    ? lowerStr.split("_")
    : lowerStr.split(" ");
    return value
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
  }
};
export {
  isFileTypeString,
  clearSessionStorage,
  isPdfFile,
  // generateQueryParamsString,
  getDistance,
  getQueryParametersAsObject,
  preciseDecimal,
  stringTemplating,
  isPendingStatusInQuery,
  setDocumentTitle,
  removeLastSlash,
  scrollToElem,
  removeParamsFromObject,
  timeZoneMomentDate,
  formatValue,
};
