export const SET_SERVICE_CALL_FAILED = "SET_SERVICE_CALL_FAILED";
export const SET_UPDATE_CONFIG = "SET_UPDATE_CONFIG";
export const SET_CONFIG_DETAILS = "SET_CONFIG_DETAILS";
export const SET_FORM_SUBMISSION_IN_PROGRESS =
  "SET_FORM_SUBMISSION_IN_PROGRESS";
export const SET_FORM_SUBMISSION_SUCCESSFUL = "SET_FORM_SUBMISSION_SUCCESSFUL";
export const SET_FORM_ERRORS = "SET_FORM_ERRORS";

export const CONFIG_ACTIONS = {
  SET_SELECTED_CONFIG_ID: "CONFIG_ACTIONS.SET_SELECTED_CONFIG_ID",
  SET_LOADER: "SET_LOADER",
  SET_SERVICE_CALL_FAILED: "SET_SERVICE_CALL_FAILED",
  SET_CONFIG_LIST: "SET_CONFIG_LIST",
  SET_CONFIG_DETAILS: "SET_CONFIG_DETAILS",
  SET_FORM_SUBMISSION_IN_PROGRESS: "SET_FORM_SUBMISSION_IN_PROGRESS",
  SET_FORM_SUBMISSION_SUCCESSFUL: "SET_FORM_SUBMISSION_SUCCESSFUL",
};

export const setLoader = (isLoading) => ({
  type: CONFIG_ACTIONS.SET_LOADER,
  payload: {
    isLoading: isLoading,
  },
});

export const setConfigList = (data) => {
  return {
    type: CONFIG_ACTIONS.SET_CONFIG_LIST,
    payload: data,
  };
};

export const setSelectedConfig = (id) => ({
  type: CONFIG_ACTIONS.SET_SELECTED_CONFIG_ID,
  payload: id,
});

export const setUpdateConfig = (configurations) => ({
  type: SET_UPDATE_CONFIG,
  payload: {
    configurations: configurations,
    isLoading: false,
    serviceCallFailed: false,
    formSubmissionInProgress: false,
    formSubmissionSuccessful: false,
  },
});

export const setServiceCallFailed = (hasFailed, error) => ({
  type: SET_SERVICE_CALL_FAILED,
  payload: {
    isLoading: false,
    serviceCallFailed: hasFailed,
    serviceCallError: error,
  },
});

export const setConfigDetails = (configDetails) => ({
  type: SET_CONFIG_DETAILS,
  payload: configDetails,
});

export const setFormSubmissionInProgress = (inProgress) => ({
  type: SET_FORM_SUBMISSION_IN_PROGRESS,
  payload: {
    formSubmissionInProgress: inProgress,
  },
});

export const setFormSubmissionSuccessful = (isSuccessful) => ({
  type: SET_FORM_SUBMISSION_SUCCESSFUL,
  payload: {
    formSubmissionSuccessful: isSuccessful,
  },
});

export const setFormErrors = (formErrors) => ({
  type: SET_FORM_ERRORS,
  payload: {
    formErrors: formErrors,
  },
});
