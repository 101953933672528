import { OFFSITE_ACTIONS } from "./action";

const initialState = {
  offsiteNotifications: [],
};

const OffsiteNotificationReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case OFFSITE_ACTIONS.SET_FORM_ERRORS:
      return {
        ...state,
        ...action.payload,
      };

    case OFFSITE_ACTIONS.SET_OFFSITE_NOTIFICATIONS:
      return {
        ...state,
        offsiteNotifications: payload,
      };
    case OFFSITE_ACTIONS.SET_FORM_SUBMISSION_IN_PROGRESS:
      return {
        ...state,
        ...action.payload,
      };
    case OFFSITE_ACTIONS.SET_FORM_SUBMISSION_SUCCESSFUL:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

export default OffsiteNotificationReducer;
