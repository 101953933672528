export const QR_ACTIONS = {
  SET_LOADER: "SET_LOADER",
  SET_SERVICE_CALL_FAILED: "SET_SERVICE_CALL_FAILED",
  SET_QRLOCATION_LIST: "SET_QRLOCATION_LIST",
  SET_QRLOCATION_DETAILS: "SET_QRLOCATION_DETAILS",
  SET_FORM_SUBMISSION_IN_PROGRESS: "SET_FORM_SUBMISSION_IN_PROGRESS",
  SET_FORM_SUBMISSION_SUCCESSFUL: "SET_FORM_SUBMISSION_SUCCESSFUL",
  SET_QRLOGS_DETAILS: "SET_QRLOGS_DETAILS",
};

export const setLoader = (isLoading) => ({
  type: QR_ACTIONS.SET_LOADER,
  payload: {
    isLoading: isLoading,
  },
});

export const setQRLocationList = (data) => {
  return {
    type: QR_ACTIONS.SET_QRLOCATION_LIST,
    payload: data,
  };
};

export const setQRLocationDetails = (qrDetails) => ({
  type: QR_ACTIONS.SET_QRLOCATION_DETAILS,
  payload: qrDetails,
});

export const setQRLogsDetails = (logsDetails) => ({
  type: QR_ACTIONS.SET_QRLOGS_DETAILS,
  payload: logsDetails,
});

export const setServiceCallFailed = (hasFailed, error) => ({
  type: QR_ACTIONS.SET_SERVICE_CALL_FAILED,
  payload: {
    isLoading: false,
    serviceCallFailed: hasFailed,
    serviceCallError: error,
  },
});
