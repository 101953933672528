import { DASHBOARD_ACTIONS } from "./action";

const initialState = {
  organisations: null,
  configOrganisations: null,
  formSubmissionInProgress: false,
  formSubmissionSuccessful: false,
};

const DashboardReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case DASHBOARD_ACTIONS.SET_CONFIG_ORGANISATIONS:
      return {
        ...state,
        configOrganisations: payload,
      };
    case DASHBOARD_ACTIONS.SET_ORGANISATIONS:
      return {
        ...state,
        organisations: payload,
      };
    case DASHBOARD_ACTIONS.SET_FORM_SUBMISSION_IN_PROGRESS:
      return {
        ...state,
        ...action.payload,
      };

    case DASHBOARD_ACTIONS.SET_FORM_SUBMISSION_SUCCESSFUL:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

export default DashboardReducer;
